import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ imageInfo }) => {
  const imageStyle = { borderRadius: '5px', margin: '3em' }
  if(!!imageInfo){
  const {  childImageSharp, coverimages } = imageInfo
const alt = ''
  if (!!coverimages && !!coverimages.childImageSharp) {
    return (
      <Img style={imageStyle} fluid={coverimages.childImageSharp.fluid} alt={alt} />
    )
  }

  if (!!childImageSharp) {
    return <Img style={imageStyle} fluid={childImageSharp.fluid} alt={alt} />
  }

  if (!!coverimages && typeof coverimages === 'string')
    return <img style={imageStyle} src={coverimages} alt={alt} />
  }
  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    coverimages: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
}

export default PreviewCompatibleImage