import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
class LightBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            photoIndex: 0,
            isOpen: false,
        }
    }
    render() {
        const { photoIndex, isOpen } = this.state
        
        return (
            <div>
               
                {isOpen && (
                    
                    <Lightbox
                    
                        mainSrc={this.props.images[photoIndex].coverimages.childImageSharp.fluid.src}
                        nextSrc={
                            this.props.images[
                                (photoIndex + 1) % this.props.images.length
                            ]
                        }
                        prevSrc={
                            this.props.images[
                                (photoIndex + this.props.images.length - 1) %
                                    this.props.images.length
                            ]
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex +
                                        this.props.images.length -
                                        1) %
                                    this.props.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex + 1) % this.props.images.length,
                            })
                        }
                    />
                )}
                <div className="liquid">
                <button
                    type="button"
                    className="button is-large is-outlined  "
                    onClick={() => this.setState({ isOpen: true })}
                >
                Galéria
                </button>
                </div>
            </div>
        )
    }
}
export default LightBox
